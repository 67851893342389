/*global Google, google, console, set_php_vars, SnazzyInfoWindow */
if (typeof set_php_vars === 'undefined' || set_php_vars === null) {
  if (typeof initMap === 'undefined') {
    function initMap() {
    }
  }
} else {
  var map,
    mapElement = jQuery('.map-container').attr('id'),
    postsURL = '/wp-json/wp/v2/map-api?per_page=100&_embed',
    zoom = set_php_vars.mapZoom || 5,
    icon = set_php_vars.mapIcon,
    style = set_php_vars.mapStyling,
	  showTerms = set_php_vars.showTerms || '',
    termId = set_php_vars.mapTermID || '',
    kml = set_php_vars.kml || '',
    kmlLayers = '',
    navAccordion = jQuery( '#map-navigation' ),
    single = set_php_vars.single,
    defaultIcon = set_php_vars.mapIcon,
    popup = set_php_vars.popup || '',
    prev_infowindow = false,
    label = 1,
    markers = [],
    markerArray = [],
    cats = [],
    count,
    items,
    noInfoWindow = set_php_vars.disableInfowindow || 'yes',
    center;

  function tooltipBuild(elm) {
    "use strict";
  }

  function initMap() {
    'use strict';

    center = new google.maps.LatLng(set_php_vars.mapCenterLat, set_php_vars.mapCenterLng);

    map = new google.maps.Map(document.getElementById(mapElement), {
      zoom: parseInt(zoom),
      center: center,
      streetViewControl: false,
      mapTypeId: 'terrain',
      mapTypeControl: false,
      mapTypeIds: ['styledMap']
    });

    jQuery.getJSON(style, function (data) {

      var styledMapType = new google.maps.StyledMapType(
        data,
        {
          name: 'Custom Map Styling'
        });

      map.mapTypes.set('styledMap', styledMapType);
      map.setMapTypeId('styledMap');
    });
    
    jQuery.ajax({
      url: postsURL,
      method: 'GET',
      success: function (data) {

        var marker,
          i,
          bounds = new google.maps.LatLngBounds();

        data.forEach(function (post) {

          var pos = new google.maps.LatLng(post.map_fields._map_lat, post.map_fields._map_lng);

          bounds.extend(pos);

          var embedded = post._embedded,
            cat = embedded["wp:term"][0][0].slug || '';

          if ( post.map_fields.hasOwnProperty('_yoast_wpseo_primary_map_category') ) {
            embedded["wp:term"][0].forEach(function(getCat) {
              if ( getCat.id === parseInt(post.map_fields._yoast_wpseo_primary_map_category) ) {
                cat = getCat.slug;
              }
            });
          }

          //<editor-fold desc="Marker Build">
          var marker = new google.maps.Marker({
            position: pos,
            map: map,
            icon: defaultIcon || icon,
            category : cat
          });

          //</editor-fold>

          var info = {
            title: post.title || post.name || '',
            desc: post.content || '',
            img: post._embedded["wp:featuredmedia"] || post._embedded["wp:featuredmedia"] || '',
            url: post.link,
            address: {
              address: post.map_fields._map_address[0] || '',
              city: post.map_fields._map_city[0] || '',
              state: post.map_fields._map_state[0] || '',
              zip: post.map_fields._map_zip[0] || '',
            },
          };

          if ( info.img !== '' ) {
            info.img = post._embedded["wp:featuredmedia"][0].media_details.sizes.medium.source_url || post._embedded["wp:featuredmedia"][0].source_url;
          }

          if (popup !== 'disabled') {

            //<editor-fold desc="Content String Build">
            var contentString = tooltipBuild(info);
            //</editor-fold>

            //<editor-fold desc="InfoWindow Functionality">
            var infowindow = new google.maps.InfoWindow({
                content: contentString,
              }
            );

            google.maps.event.addListener(marker, 'click', (function (marker, i) {

              return function () {
                if (!jQuery.isEmptyObject(prev_infowindow)) {
                  prev_infowindow.close();
                }
                infowindow = new google.maps.InfoWindow({
                  content: contentString,
                  // maxWidth: 470
                });
                prev_infowindow = infowindow;
                infowindow.open(map, marker);
              };

            })(marker, i));

          }

          jQuery('.kml-item').on('click', function(e) {
            infowindow.close();
          });


          //<editor-fold desc="Hover Icons Uncomment to trigger">

          // marker.addListener('mouseover', function () {
          //   marker.setIcon(set_php_vars.mapHoverIcon);
          // });
          //
          // marker.addListener('mouseout', function () {
          //   marker.setIcon(defaultIcon);
          // });
          //
          // marker.addListener('click', function () {
          //   marker.setIcon(set_php_vars.mapHoverIcon);
          // });
          //</editor-fold>

          //</editor-fold>

          if ( post.title.rendered === 'Home' )
            marker.setVisible(true);
          else
            marker.setVisible(false);

          markers.push(marker);
          marker.setZIndex(google.maps.Marker.MAX_ZINDEX + 1);
	
	        if (popup !== 'disabled') {
		        google.maps.event.addListener( map, 'click', function () {
			        infowindow.close();
		        } );
	        }

        });

      },
      error: function (request, status, error) {
        console.log('error');
        console.log(request);
        console.log(status);
        console.log(error);

      },
      // cache: true
    });

    function toggleMarkers( obj, category ) {

      var i,
        $obj = jQuery( obj );

      for ( i = 0; i < markers.length; i++ ) {

        if ( markers[ i ].category === category ) {
          if ( !$obj.parent().hasClass( 'collapsed' ) ) {
            markers[ i ].setVisible( false );
            map.setCenter( map.getCenter() );
          } else {
            markers[ i ].setVisible( true );
            fitBoundsToVisibleMarkers();
          }
        }
        else if ( category === 'show-all' ) {

          if ( !$obj.parent().hasClass( 'collapsed' ) ) {
            markers[ i ].setVisible( true );
            fitBoundsToVisibleMarkers();
          } else {
            markers[ i ].setVisible( false );
            map.setCenter( map.getCenter() );
          }

        }
        else {
          markers[ i ].setVisible( false );
          map.setCenter( map.getCenter() );
        }


        if ( markers[ i ].category === 'home' ) {
          markers[ i ].setVisible( true );
        }

      }

    }

    function fitBoundsToVisibleMarkers() {

      var bounds = new google.maps.LatLngBounds();

      for ( var i = 0; i < markers.length; i++ ) {
        if ( markers[ i ].getVisible() ) {
          bounds.extend( markers[ i ].getPosition() );
        } else {

        }
      }

      map.fitBounds( bounds );

    }

    navAccordion.on( 'click', '#show-all', function() {
      navAccordion.find( '.collapse' ).collapse( 'hide' );
    } );

    jQuery( '[data-category]' ).on( 'click', function( e ) {

      var $this = jQuery( this ),
        category = jQuery( this ).attr( 'data-category' );

      kmlLayers.setMap(null);

      if ( category === 'show-all' ) {
        e.preventDefault();
        if ( !jQuery('#amenities-navigation-toggler').hasClass( 'opened-nav' ) || !jQuery('#amenitied-navigation-toggler').hasClass('still-toggled') ) {
          jQuery( this ).parent().removeClass( 'collapsed' );
        } else {
          jQuery( this ).parent().addClass( 'collapsed' );
        }

      } else {
        jQuery( '#show-all .category-name a' ).addClass( 'collapsed' );
      }

      $this.parents( '.category' ).find( '.category-items' ).addClass( 'open' );

      toggleMarkers( $this, category );

    } );

    var theArray,
      kmlLink = jQuery('.kml-item'),
      layers = [];

    function addLayer(layer, array) { // ADD KML LAYERS TO THE MAP

      var bounds = new google.maps.LatLngBounds(center),
        fileName = layer,
        theArray = array,
        mapLayer = new google.maps.KmlLayer( {
          url : fileName
        });

      layers.push(mapLayer);

      mapLayer.setMap(map);
      theArray.push(mapLayer);
      //map.fitBounds(bounds); // ADJUST PAN AND ZOOM BASED ON NEWLY ADDED MARKERS

    }

    kmlLayers = new google.maps.KmlLayer();

    kmlLayers.setMap(map);

    kmlLink.on('click', function() {

      var $this = jQuery(this),
        kmlFile = $this.data('kml-test'),
        kmlName = $this.attr('id');

      if ( !$this.hasClass('kml-on') ) {
        kmlLink.removeClass('kml-on');
        $this.addClass('kml-on');
      }


      kmlLayers.setMap(null);

      for ( var i = 0; i < markers.length; i++ ) {
        markers[i].setVisible(false);
      }

      if ( $this.hasClass('kml-on') ) {
        kmlLayers = new google.maps.KmlLayer({
          url: kmlFile,
          map: map,
        });
      } else {
        kmlLayers.setMap(null);
      }

    });

    google.maps.event.addDomListener(window, 'resize', function () {
      var center = map.getCenter();
      google.maps.event.trigger(map, "resize");
      map.setCenter(center);
    });

  }

}